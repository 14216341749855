import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';
import { Pill } from '@andes/badge';

import { useSearch } from '../../hooks/context';

const namespace = 'ui-search-listing-disclaimer';
const ListingDisclaimer = ({ text, currentLayout }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <div
      className={classnames(`${namespace} ${namespace}--${currentLayout.id}`, { 'shops__listing-disclaimer': isShops })}
    >
      <div
        className={classnames(`${namespace}__card ${namespace}__card--${currentLayout.id}`, {
          'shops__listing-disclaimer-card': isShops,
        })}
      >
        <Pill
          className={classnames(`${namespace}__icon`, { 'shops__listing-disclaimer-icon': isShops })}
          size="small"
          color="accent"
          hierarchy="loud"
          contentType="icon"
        />
        <p className={classnames(`${namespace}__text`, { 'shops-custom-secondary-font': isShops })}>{text}</p>
      </div>
    </div>
  );
};

ListingDisclaimer.propTypes = {
  currentLayout: shape({}).isRequired,
  text: string.isRequired,
};

export default ListingDisclaimer;
